import { useEffect, useState } from "react";
import CustomTableForReporting from "../../../../pages/reporting/table";
import { Select, Col, Row } from "antd";
import moment from "moment";
import { getSelectedOutlet } from "../../../../redux/feature/outletSlice";
import { useSelector } from "react-redux";

function ReportingRegister() {
  const [searchText, setSearchText] = useState("");
  const { selectedOutlet } = useSelector(getSelectedOutlet) || {};
  const [filterValue, setFilterValue] = useState({
    startDate: "", // for the start date
    endDate: "",
    outletIds: "",
  });
  const columns = [
    {
      title: "Bar Code",
      dataIndex: "barcode",
      key: "barcode",
    },
    {
      title: "Sales (inc. tax)",
      dataIndex: "purchaseCost",
      key: "purchaseCost",
    },
    {
      title: "Sales (Ex. tax)",
      dataIndex: "salesExcludingTax",
      key: "salesExcludingTax",
    },
    {
      title: "Quantity Sold",
      dataIndex: "quantitySold",
      key: "quantitySold",
    },
    {
      title: "Order discounts",
      dataIndex: "orderDiscount",
      key: "orderDiscount",
    },
  ];
  const handleChange = (value) => {
    let start, end;
    const today = moment(); // Keep the original reference intact

    switch (value) {
      case "today":
        start = today.clone().startOf("day");
        end = today.clone().endOf("day");
        break;
      case "yesterday":
        start = today.clone().subtract(1, "days").startOf("day");
        end = today.clone().subtract(1, "days").endOf("day");
        break;
      case "7_days":
        start = today.clone().subtract(7, "days").startOf("day");
        end = today.clone().endOf("day");
        break;
      case "30_days":
        start = today.clone().subtract(30, "days").startOf("day");
        end = today.clone().endOf("day");
        break;
      case "1_month":
        start = today.clone().startOf("month");
        end = today.clone().endOf("month");
        break;
      case "2_month":
        start = today.clone().subtract(1, "month").startOf("month");
        end = today.clone().subtract(1, "month").endOf("month");
        break;
      default:
        start = today.clone().startOf("day");
        end = today.clone().endOf("day");
        break;
    }

    setFilterValue((prevState) => ({
      ...prevState,
      startDate: start.toISOString(),
      endDate: end.toISOString(),
    }));
  };

  useEffect(() => {
    setFilterValue((prevState) => ({
      ...prevState,
      // outletIds: selectedOutlet?._id,
    }));
  }, []);
  return (
    <>
      <div className="flex flex-col justify-between">
        <div className="flex items-center gap-6 mb-4">
          <form action="" className="relative w-2/6">
            <input
              type="text"
              placeholder="Find by register"
              onChange={(e) => setSearchText(e.target.value)}
              className="block leading-none py-2 xxl:py-3 pr-6 xxl:pr-10 pl-12 xxl:pl-12 border w-full rounded-full"
            />
            <div className="absolute top-2 xxl:top-2 left-4 xxl:left-4 w-6 h-6">
              <svg
                width="18"
                height="18"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.7555 18.6065L16.3182 15.2458L16.2376 15.1233C16.0878 14.9742 15.883 14.8902 15.6692 14.8902C15.4554 14.8902 15.2505 14.9742 15.1007 15.1233C12.1795 17.8033 7.67815 17.949 4.58201 15.4637C1.48586 12.9784 0.755668 8.63337 2.87568 5.31017C4.9957 1.98697 9.30807 0.716847 12.9528 2.34214C16.5976 3.96743 18.4438 7.98379 17.267 11.7276C17.1823 11.9981 17.2515 12.2922 17.4487 12.4992C17.6459 12.7062 17.9411 12.7946 18.223 12.7311C18.505 12.6676 18.7309 12.4619 18.8156 12.1914C20.2224 7.74864 18.0977 2.96755 13.8161 0.941058C9.53449 -1.08544 4.38084 0.250824 1.68905 4.08542C-1.00273 7.92001 -0.424821 13.1021 3.04893 16.2795C6.52268 19.4569 11.8498 19.6759 15.5841 16.7949L18.6277 19.7705C18.942 20.0765 19.4502 20.0765 19.7645 19.7705C20.0785 19.4602 20.0785 18.9606 19.7645 18.6503L19.7555 18.6065Z"
                  fill="black"
                />
              </svg>
            </div>
          </form>
          <div className="flex items-center gap-2">
            <label className="inline-flex items-center align-sub justify-end cursor-pointer relative">
              <input
                type="checkbox"
                className="peer h-[1.4rem] aspect-square cursor-pointer transition-all appearance-none rounded-md border checked:bg-ufoGreen checked:border-ufoGreen"
              />
              <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
            </label>
            <div className="block text-black text-md text-center">
              Include deleted
            </div>
          </div>
        </div>
        <Row className="mb-4">
          <Col span={20}></Col>
          <Col span={4}>
            <Select
              className="daysPickerWrap"
              defaultValue="Today"
              style={{ width: "auto" }}
              onChange={handleChange}
              options={[
                { value: "today", label: "Today" },
                { value: "yesterday", label: "Yesterday" },
                { value: "7_days", label: "Last 7 Days" },
                { value: "30_days", label: "Last 30 Days" },
                { value: "1_month", label: "This Month" },
                { value: "2_month", label: "Last Month" },
              ]}
            />
          </Col>
        </Row>
        <CustomTableForReporting
          columns={columns}
          endPoint={`report/sales-register`}
          search={searchText}
          filters={filterValue}
          showActions={false}
        />
      </div>
    </>
  );
}
export default ReportingRegister;
