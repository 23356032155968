import { Col, Row, Select } from "antd"
import CustomTableForReporting from "../../../../pages/reporting/table"
import { useEffect, useState } from "react";
import moment from "moment";

function SalesInstruction() {
    const [filterValue, setFilterValue] = useState({
        startDate: "", // for the start date
        endDate: "",
        outletIds: "",
    });
    useEffect(() => {
        setFilterValue((prevState) => ({
            ...prevState,
            // outletIds: selectedOutlet?._id,
        }));
    }, []);
    const columnsSalesSummary = [
        {
            title: "Date",
            dataIndex: "salesDate",
            key: "salesDate",
            // render: (_, row) => <p>{new Date(row.date).toDateString()}</p>,
        },
        {
            title: "Sales",
            dataIndex: "sales",
            key: "sales",
        },
        {
            title: "discount",
            dataIndex: "discount",
            key: "discount",
        },
        {
            title: "Margin",
            dataIndex: "margin",
            key: "margin",
        },
        {
            title: "Refunds",
            dataIndex: "refund",
            key: "refund",
        },
        {
            title: "Avg. Sale Value",
            dataIndex: "avgSales",
            key: "avgSales",
        },
    ];
    const handleChange = (value) => {
        let start, end;
        const today = moment(); // Keep the original reference intact

        switch (value) {
            case "today":
                start = today.clone().startOf("day");
                end = today.clone().endOf("day");
                break;
            case "yesterday":
                start = today.clone().subtract(1, "days").startOf("day");
                end = today.clone().subtract(1, "days").endOf("day");
                break;
            case "7_days":
                start = today.clone().subtract(7, "days").startOf("day");
                end = today.clone().endOf("day");
                break;
            case "30_days":
                start = today.clone().subtract(30, "days").startOf("day");
                end = today.clone().endOf("day");
                break;
            case "1_month":
                start = today.clone().startOf("month");
                end = today.clone().endOf("month");
                break;
            case "2_month":
                start = today.clone().subtract(1, "month").startOf("month");
                end = today.clone().subtract(1, "month").endOf("month");
                break;
            default:
                start = today.clone().startOf("day");
                end = today.clone().endOf("day");
                break;
        }
        setFilterValue((prevState) => ({
            ...prevState,
            startDate: start.toISOString(),
            endDate: end.toISOString(),
        }));
    };

    return (
        <>
            <Row>
                <Col span={20}></Col>
                <Col span={4}>
                    <Select
                        className="daysPickerWrap"
                        defaultValue="Today"
                        style={{ width: "auto" }}
                        onChange={handleChange}
                        options={[
                            { value: "today", label: "Today" },
                            { value: "yesterday", label: "Yesterday" },
                            { value: "7_days", label: "Last 7 Days" },
                            { value: "30_days", label: "Last 30 Days" },
                            { value: "1_month", label: "This Month" },
                            { value: "2_month", label: "Last Month" },
                        ]}
                    />
                </Col>
            </Row>
            <div className="flex flex-col justify-between mt-4">
                {/* {filterValue.outletIds?.length && */}
                <CustomTableForReporting
                    columns={columnsSalesSummary}
                    endPoint={`report/sales-summary`}
                    filters={filterValue}
                    showActions={false}
                />
                {/* // } */}
            </div>
        </>
    )
}
export default SalesInstruction