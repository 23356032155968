import React, { useEffect } from "react";
import {
  ResetLoading,
  getLoadStatus,
  getTotalRequest,
} from "../../../redux/feature/loader";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

const AppLoader = ({ status, type, showInfo = false }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoadStatus);
  const totalRequest = useSelector(getTotalRequest);

  const getPercentage = (loadingStatus, totalLoader) => {
    const percentage = (loadingStatus / totalLoader) * 100;
    return percentage < 90 ? "" : percentage;
  };

  useEffect(() => {
    if (loading === totalRequest) {
      dispatch(ResetLoading());
    }

    // Auto-reset loader if it's still running after 10 seconds
    const timeout = setTimeout(() => {
      if (loading < totalRequest) {
        dispatch(ResetLoading());
      }
    }, 10000); // 10 seconds

    return () => clearTimeout(timeout); // Cleanup timeout on unmount
  }, [loading, totalRequest, dispatch]);

  return (
    <div className={"custom-api-progress"}>
      {loading < totalRequest && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)", // Background overlay
              zIndex: 9999,
            }}
          >
            <Spin size="large" />
          </div>
          {getPercentage(loading, totalRequest)}
        </>
      )}
    </div>
  );
};

export default AppLoader;
