import React, { useState } from "react";
import { Col, Row, Select, Space } from "antd";
import { Tabs, DatePicker } from "antd";
import CustomTable from "../../../component/shared/customTable";
import CustomTableForReporting from "../table";
import moment from "moment";
import { useEffect } from "react";
import { getSelectedOutlet } from "../../../redux/feature/outletSlice";
import { useSelector } from "react-redux";
import ReportingRegister from "../../../component/reporting/sales/register";
import ReportSalesCategory from "../../../component/reporting/sales/category";
import { getAllSalesReportingDetails } from "../../../services/reporting";
import SalesInstruction from "../../../component/reporting/sales/salesInstruction";

function SalesReporting() {
  const { TabPane } = Tabs;
  const { RangePicker } = DatePicker;
  const { selectedOutlet } = useSelector(getSelectedOutlet) || {};
  const [headerCard, setHeaderCard] = useState({
    sales: 0,
    refund: 0,
    tax: 0,
    discount: 0,
    surcharge: 0,
    cogs: 0,
    salesDate: "",
    grossProfit: 0,
    margin: 0,
    avgSales: 0
  })

  const [filterValue, setFilterValue] = useState({
    startDate: "", // for the start date
    endDate: "",
    outletIds: "",
  });
  useEffect(() => {
    setFilterValue((prevState) => ({
      ...prevState,
      // outletIds: selectedOutlet?._id,
    }));
  }, []);

  const onChange = (key) => {
    console.log(key);
  };
  const handleChange = (value) => {
    let start, end;
    const today = moment(); // Keep the original reference intact

    switch (value) {
      case "today":
        start = today.clone().startOf("day");
        end = today.clone().endOf("day");
        break;
      case "yesterday":
        start = today.clone().subtract(1, "days").startOf("day");
        end = today.clone().subtract(1, "days").endOf("day");
        break;
      case "7_days":
        start = today.clone().subtract(7, "days").startOf("day");
        end = today.clone().endOf("day");
        break;
      case "30_days":
        start = today.clone().subtract(30, "days").startOf("day");
        end = today.clone().endOf("day");
        break;
      case "1_month":
        start = today.clone().startOf("month");
        end = today.clone().endOf("month");
        break;
      case "2_month":
        start = today.clone().subtract(1, "month").startOf("month");
        end = today.clone().subtract(1, "month").endOf("month");
        break;
      default:
        start = today.clone().startOf("day");
        end = today.clone().endOf("day");
        break;
    }
    setFilterValue((prevState) => ({
      ...prevState,
      startDate: start.toISOString(),
      endDate: end.toISOString(),
    }));
  };

  const columnsSalesSummary = [
    {
      title: "Date",
      dataIndex: "salesDate",
      key: "salesDate",
      // render: (_, row) => <p>{new Date(row.date).toDateString()}</p>,
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
    },
    {
      title: "discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Margin",
      dataIndex: "margin",
      key: "margin",
    },
    {
      title: "Refunds",
      dataIndex: "refund",
      key: "refund",
    },
    {
      title: "Avg. Sale Value",
      dataIndex: "avgSales",
      key: "avgSales",
    },
  ];

  function sumArray(data) {
    return data.reduce((acc, obj) => {
      for (let key in obj) {
        if (key !== "salesDate") {
          acc[key] = ((acc[key] || 0) + obj[key]);
        } else {
          acc[key] = obj[key]; // Keep salesDate from the last object
        }
      }
      return acc;
    }, {});
  }
  const getAllCardDetails = async () => {
    try {
      const response = await getAllSalesReportingDetails();
      const values = sumArray(response.data.data)
      console.log("kasdkasnd", values)
      setHeaderCard(values)
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllCardDetails();
  }, []);

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:px-3 xxl:px-6 sm:py-4 xxl:py-8 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8">
          <h2 className="sm:text-xl xxl:text-2xl font-medium">Sales</h2>
          <div className="flex items-center gap-6">
            <div className="relative">
              <div className="flex items-center gap-1 leading-none block py-2 px-4 xxl:py-4 xxl:px-6 bg-redOrange rounded-full text-white font-medium border border-redOrange cursor-pointer uppercase flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#ffffff"
                  viewBox="0 0 256 256"
                >
                  <path d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0V156.69l50.34-50.35a8,8,0,0,1,11.32,0L128,132.69,180.69,80H160a8,8,0,0,1,0-16h40a8,8,0,0,1,8,8v40a8,8,0,0,1-16,0V91.31l-58.34,58.35a8,8,0,0,1-11.32,0L96,123.31l-56,56V200H224A8,8,0,0,1,232,208Z"></path>
                </svg>
                <span className="uppercase">Export</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#ffffff"
                  viewBox="0 0 256 256"
                >
                  <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-auto scrollbar">
          <Tabs
            defaultActiveKey="1"
            className="filtersTabMain"
            onChange={onChange}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <TabPane tab="Sales Summary" key="1">
              <div className="flex sm:gap-3 xxl:gap-6 w-full">
                <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
                  <div className="lg:text-2xl xxl:text-3xl font-medium text-amber-400">
                    {Number(headerCard?.sales).toFixed(2)}
                  </div>
                  <div className="flex items-center justify-start mb-5 gap-1">
                    <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Sales (inc. tax)
                    </div>
                    <svg
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#a1a1a1"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Change
                    </span>
                    <span className="text-[0.8rem] xxl:txt-md font-semibold text-neutral-500">
                      0.00%
                    </span>
                  </div>
                </div>
                <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
                  <div className="lg:text-2xl xxl:text-3xl font-medium text-emerald-900">
                    {Number(headerCard?.avgSales).toFixed(2)}
                  </div>
                  <div className="flex items-center justify-start mb-5 gap-1">
                    <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Avg Sales
                    </div>
                    <svg
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#a1a1a1"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Change
                    </span>
                    <span className="text-[0.8rem] xxl:txt-md font-semibold text-neutral-500">
                      0.00%
                    </span>
                  </div>
                </div>
                <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
                  <div className="lg:text-2xl xxl:text-3xl font-medium text-cyan-400">
                    {Number(headerCard?.discount).toFixed(2)}
                  </div>
                  <div className="flex items-center justify-start mb-5 gap-1">
                    <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Discount
                    </div>
                    <svg
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#a1a1a1"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Change
                    </span>
                    <span className="text-[0.8rem] xxl:txt-md font-semibold text-neutral-500">
                      0.00%
                    </span>
                  </div>
                </div>
                <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
                  <div className="lg:text-2xl xxl:text-3xl font-medium text-fuchsia-600">
                    {Number(headerCard?.refund).toFixed(2)}
                  </div>
                  <div className="flex items-center justify-start mb-5 gap-1">
                    <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Refund
                    </div>
                    <svg
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#a1a1a1"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Change
                    </span>
                    <span className="text-[0.8rem] xxl:txt-md font-semibold text-neutral-500">
                      0.00%
                    </span>
                  </div>
                </div>
                <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
                  <div className="lg:text-2xl xxl:text-3xl font-medium text-rose-600">
                    Rs0.00
                  </div>
                  <div className="flex items-center justify-start mb-5 gap-1">
                    <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Sales (inc. tax)
                    </div>
                    <svg
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#a1a1a1"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Change
                    </span>
                    <span className="text-[0.8rem] xxl:txt-md font-semibold text-neutral-500">
                      0.00%
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex sm:gap-3 xxl:gap-6 w-full">
                <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
                  <div className="lg:text-2xl xxl:text-3xl font-medium text-amber-400">
                    {Number(headerCard?.cogs).toFixed(2)}
                  </div>
                  <div className="flex items-center justify-start gap-1">
                    <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Cogs
                    </div>
                    <svg
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#a1a1a1"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
                    </svg>
                  </div>
                </div>
                <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
                  <div className="lg:text-2xl xxl:text-3xl font-medium text-emerald-900">
                    {Number(headerCard?.grossProfit).toFixed(2)}

                  </div>
                  <div className="flex items-center justify-start gap-1">
                    <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Gross Profit
                    </div>
                    <svg
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#a1a1a1"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
                    </svg>
                  </div>
                </div>
                <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
                  <div className="lg:text-2xl xxl:text-3xl font-medium text-cyan-400">
                    {Number(headerCard?.margin).toFixed(2)}

                  </div>
                  <div className="flex items-center justify-start gap-1">
                    <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Margin
                    </div>
                    <svg
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#a1a1a1"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
                    </svg>
                  </div>
                </div>
                <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
                  <div className="lg:text-2xl xxl:text-3xl font-medium text-fuchsia-600">
                    {Number(headerCard?.surcharge).toFixed(2)}

                  </div>
                  <div className="flex items-center justify-start gap-1">
                    <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Surcharge
                    </div>
                    <svg
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#a1a1a1"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
                    </svg>
                  </div>
                </div>
                <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
                  <div className="lg:text-2xl xxl:text-3xl font-medium text-rose-600">
                    Rs0.00
                  </div>
                  <div className="flex items-center justify-start gap-1">
                    <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
                      Surchaarge / Shipping
                    </div>
                    <svg
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#a1a1a1"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <Row>
                <Col span={20}></Col>
                <Col span={4}>
                  <Select
                    className="daysPickerWrap"
                    defaultValue="Today"
                    style={{ width: "auto" }}
                    onChange={handleChange}
                    options={[
                      { value: "today", label: "Today" },
                      { value: "yesterday", label: "Yesterday" },
                      { value: "7_days", label: "Last 7 Days" },
                      { value: "30_days", label: "Last 30 Days" },
                      { value: "1_month", label: "This Month" },
                      { value: "2_month", label: "Last Month" },
                    ]}
                  />
                </Col>
              </Row>
              <div className="flex flex-col justify-between mt-4">
                {/* {filterValue.outletIds?.length && */}
                <CustomTableForReporting
                  columns={columnsSalesSummary}
                  endPoint={`report/sales-summary`}
                  filters={filterValue}
                  showActions={false}
                />
                {/* // } */}
              </div>
            </TabPane>

            <TabPane tab="Sales (By Items)" key="2">
              <ReportingRegister />

            </TabPane>

            <TabPane tab="Sales Instruction" key="3">
              <SalesInstruction />
              {/* <div className="flex flex-col justify-between">
                <div className="flex items-center flex-wrap gap-2 mb-4">
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Parked
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Completed Sales
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Refunded
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Layby
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Backorder
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      On Account
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Exchanged
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Partial Fullfilled
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                </div>

                <div className="flex items-center justify-between gap-12 mb-4">
                  <form action="" className="relative w-2/6">
                    <input
                      type="text"
                      placeholder="Find by order number"
                      className="block leading-none py-2 xxl:py-3 pr-6 xxl:pr-10 pl-12 xxl:pl-12 border w-full rounded-full"
                    />
                    <div className="absolute top-2 xxl:top-2 left-4 xxl:left-4 w-6 h-6">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7555 18.6065L16.3182 15.2458L16.2376 15.1233C16.0878 14.9742 15.883 14.8902 15.6692 14.8902C15.4554 14.8902 15.2505 14.9742 15.1007 15.1233C12.1795 17.8033 7.67815 17.949 4.58201 15.4637C1.48586 12.9784 0.755668 8.63337 2.87568 5.31017C4.9957 1.98697 9.30807 0.716847 12.9528 2.34214C16.5976 3.96743 18.4438 7.98379 17.267 11.7276C17.1823 11.9981 17.2515 12.2922 17.4487 12.4992C17.6459 12.7062 17.9411 12.7946 18.223 12.7311C18.505 12.6676 18.7309 12.4619 18.8156 12.1914C20.2224 7.74864 18.0977 2.96755 13.8161 0.941058C9.53449 -1.08544 4.38084 0.250824 1.68905 4.08542C-1.00273 7.92001 -0.424821 13.1021 3.04893 16.2795C6.52268 19.4569 11.8498 19.6759 15.5841 16.7949L18.6277 19.7705C18.942 20.0765 19.4502 20.0765 19.7645 19.7705C20.0785 19.4602 20.0785 18.9606 19.7645 18.6503L19.7555 18.6065Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </form>
                </div>

                <table className="table-auto w-full whitespace-nowrap overflow-y-scroll">
                  <thead>
                    <tr>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">Order #</span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">Date</span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">Customer</span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <span className="text-sm xxl:text-lg">
                          Items ( Quantity )
                        </span>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">Status</span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <span className="text-sm xxl:text-lg">
                          Payment types
                        </span>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">
                            Order total
                          </span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">User</span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        colspan="12"
                        className="py-6 text-left border-b border-lightBlue"
                      >
                        <span className="block text-lightGrey text-lg text-center opacity-70">
                          No Records Found
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </TabPane>

            {/* <TabPane tab="Laybys" key="4">
              <div className="flex flex-col justify-between">
                <div className="flex items-center justify-between gap-12 mb-4">
                  <form action="" className="relative w-2/6">
                    <input
                      type="text"
                      placeholder="Find by order number and/or customer name"
                      className="block leading-none py-2 xxl:py-3 pr-6 xxl:pr-10 pl-12 xxl:pl-12 border w-full rounded-full"
                    />
                    <div className="absolute top-2 xxl:top-2 left-4 xxl:left-4 w-6 h-6">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7555 18.6065L16.3182 15.2458L16.2376 15.1233C16.0878 14.9742 15.883 14.8902 15.6692 14.8902C15.4554 14.8902 15.2505 14.9742 15.1007 15.1233C12.1795 17.8033 7.67815 17.949 4.58201 15.4637C1.48586 12.9784 0.755668 8.63337 2.87568 5.31017C4.9957 1.98697 9.30807 0.716847 12.9528 2.34214C16.5976 3.96743 18.4438 7.98379 17.267 11.7276C17.1823 11.9981 17.2515 12.2922 17.4487 12.4992C17.6459 12.7062 17.9411 12.7946 18.223 12.7311C18.505 12.6676 18.7309 12.4619 18.8156 12.1914C20.2224 7.74864 18.0977 2.96755 13.8161 0.941058C9.53449 -1.08544 4.38084 0.250824 1.68905 4.08542C-1.00273 7.92001 -0.424821 13.1021 3.04893 16.2795C6.52268 19.4569 11.8498 19.6759 15.5841 16.7949L18.6277 19.7705C18.942 20.0765 19.4502 20.0765 19.7645 19.7705C20.0785 19.4602 20.0785 18.9606 19.7645 18.6503L19.7555 18.6065Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </form>
                </div>

                <div className="w-full scrollbar overflow-y-scroll">
                  <table className="table-auto w-full whitespace-nowrap">
                    <thead>
                      <tr>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">Order #</span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">Date</span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">
                              Customer
                            </span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <span className="text-sm xxl:text-lg">
                            Items ( Quantity )
                          </span>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">Status</span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <span className="text-sm xxl:text-lg">
                            Payment types
                          </span>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">
                              Order total
                            </span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">User</span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          colspan="12"
                          className="py-6 text-left border-b border-lightBlue"
                        >
                          <span className="block text-lightGrey text-lg text-center opacity-70">
                            No Records Found
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPane> */}

            <TabPane tab="By Category" key="5">
              <ReportSalesCategory />
            </TabPane>

            <TabPane tab="Registers" key="6">
              <ReportingRegister />
            </TabPane>

            <TabPane tab="Users" key="7">
              <div className="flex flex-col justify-between">
                <div className="flex items-center gap-6 mb-4">
                  <form action="" className="relative w-2/6">
                    <input
                      type="text"
                      placeholder="Find by user"
                      className="block leading-none py-2 xxl:py-3 pr-6 xxl:pr-10 pl-12 xxl:pl-12 border w-full rounded-full"
                    />
                    <div className="absolute top-2 xxl:top-2 left-4 xxl:left-4 w-6 h-6">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7555 18.6065L16.3182 15.2458L16.2376 15.1233C16.0878 14.9742 15.883 14.8902 15.6692 14.8902C15.4554 14.8902 15.2505 14.9742 15.1007 15.1233C12.1795 17.8033 7.67815 17.949 4.58201 15.4637C1.48586 12.9784 0.755668 8.63337 2.87568 5.31017C4.9957 1.98697 9.30807 0.716847 12.9528 2.34214C16.5976 3.96743 18.4438 7.98379 17.267 11.7276C17.1823 11.9981 17.2515 12.2922 17.4487 12.4992C17.6459 12.7062 17.9411 12.7946 18.223 12.7311C18.505 12.6676 18.7309 12.4619 18.8156 12.1914C20.2224 7.74864 18.0977 2.96755 13.8161 0.941058C9.53449 -1.08544 4.38084 0.250824 1.68905 4.08542C-1.00273 7.92001 -0.424821 13.1021 3.04893 16.2795C6.52268 19.4569 11.8498 19.6759 15.5841 16.7949L18.6277 19.7705C18.942 20.0765 19.4502 20.0765 19.7645 19.7705C20.0785 19.4602 20.0785 18.9606 19.7645 18.6503L19.7555 18.6065Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </form>
                  <div className="flex items-center gap-2">
                    <label className="inline-flex items-center align-sub justify-end cursor-pointer relative">
                      <input
                        type="checkbox"
                        className="peer h-[1.4rem] aspect-square cursor-pointer transition-all appearance-none rounded-md border checked:bg-ufoGreen checked:border-ufoGreen"
                      />
                      <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </label>
                    <div className="block text-black text-md text-center">
                      Do not include 'Parked and LayBy orders'
                    </div>
                  </div>
                </div>

                <table className="table-auto w-full whitespace-nowrap overflow-y-scroll">
                  <thead>
                    <tr>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">User</span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">
                            Sales (Ex. tax)
                          </span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">Refunds</span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">
                            Item discounts
                          </span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">
                            Order discounts
                          </span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
                        Buckminster Estes
                      </td>
                      <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
                        Rs0.00
                      </td>
                      <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
                        Rs0.00
                      </td>
                      <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
                        Rs0.00
                      </td>
                      <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
                        Rs0.00
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </TabPane>

            <TabPane tab="Customer Groupe (Tiers)" key="8">
              <div className="flex flex-col justify-between">
                <div className="flex items-center gap-6 mb-4">
                  <form action="" className="relative w-2/6">
                    <input
                      type="text"
                      placeholder="Find by customer group (tiers) name"
                      className="block leading-none py-2 xxl:py-3 pr-6 xxl:pr-10 pl-12 xxl:pl-12 border w-full rounded-full"
                    />
                    <div className="absolute top-2 xxl:top-2 left-4 xxl:left-4 w-6 h-6">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7555 18.6065L16.3182 15.2458L16.2376 15.1233C16.0878 14.9742 15.883 14.8902 15.6692 14.8902C15.4554 14.8902 15.2505 14.9742 15.1007 15.1233C12.1795 17.8033 7.67815 17.949 4.58201 15.4637C1.48586 12.9784 0.755668 8.63337 2.87568 5.31017C4.9957 1.98697 9.30807 0.716847 12.9528 2.34214C16.5976 3.96743 18.4438 7.98379 17.267 11.7276C17.1823 11.9981 17.2515 12.2922 17.4487 12.4992C17.6459 12.7062 17.9411 12.7946 18.223 12.7311C18.505 12.6676 18.7309 12.4619 18.8156 12.1914C20.2224 7.74864 18.0977 2.96755 13.8161 0.941058C9.53449 -1.08544 4.38084 0.250824 1.68905 4.08542C-1.00273 7.92001 -0.424821 13.1021 3.04893 16.2795C6.52268 19.4569 11.8498 19.6759 15.5841 16.7949L18.6277 19.7705C18.942 20.0765 19.4502 20.0765 19.7645 19.7705C20.0785 19.4602 20.0785 18.9606 19.7645 18.6503L19.7555 18.6065Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </form>
                </div>

                <table className="table-auto w-full whitespace-nowrap overflow-y-scroll">
                  <thead>
                    <tr>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">
                            Group (tiers)
                          </span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">
                            Standard discount
                          </span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">
                            Sales (Ex. tax)
                          </span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">
                            Order discounts
                          </span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">Refunds</span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
                        Retail (default)
                      </td>
                      <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
                        0%
                      </td>
                      <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
                        Rs0.00
                      </td>
                      <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
                        Rs0.00
                      </td>
                      <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
                        Rs0.00
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </TabPane>

            <TabPane tab="On Account" key="9">
              <div className="flex flex-col justify-between">
                <div className="flex items-center gap-6 mb-4">
                  <form action="" className="relative w-2/6">
                    <input
                      type="text"
                      placeholder="Find by order number and/or customer name"
                      className="block leading-none py-2 xxl:py-3 pr-6 xxl:pr-10 pl-12 xxl:pl-12 border w-full rounded-full"
                    />
                    <div className="absolute top-2 xxl:top-2 left-4 xxl:left-4 w-6 h-6">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7555 18.6065L16.3182 15.2458L16.2376 15.1233C16.0878 14.9742 15.883 14.8902 15.6692 14.8902C15.4554 14.8902 15.2505 14.9742 15.1007 15.1233C12.1795 17.8033 7.67815 17.949 4.58201 15.4637C1.48586 12.9784 0.755668 8.63337 2.87568 5.31017C4.9957 1.98697 9.30807 0.716847 12.9528 2.34214C16.5976 3.96743 18.4438 7.98379 17.267 11.7276C17.1823 11.9981 17.2515 12.2922 17.4487 12.4992C17.6459 12.7062 17.9411 12.7946 18.223 12.7311C18.505 12.6676 18.7309 12.4619 18.8156 12.1914C20.2224 7.74864 18.0977 2.96755 13.8161 0.941058C9.53449 -1.08544 4.38084 0.250824 1.68905 4.08542C-1.00273 7.92001 -0.424821 13.1021 3.04893 16.2795C6.52268 19.4569 11.8498 19.6759 15.5841 16.7949L18.6277 19.7705C18.942 20.0765 19.4502 20.0765 19.7645 19.7705C20.0785 19.4602 20.0785 18.9606 19.7645 18.6503L19.7555 18.6065Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </form>
                </div>

                <table className="table-auto w-full whitespace-nowrap overflow-y-scroll">
                  <thead>
                    <tr>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">Order #</span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">Date</span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">Customer</span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">
                            Order total
                          </span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                      <th className="py-4 border-y-[0.1rem] text-left">
                        <div className="flex items-center justify-start">
                          <span className="text-sm xxl:text-lg">
                            Outstanding
                          </span>
                          <div className="ml-10 flex flex-col spinner">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                fill="#D7D7D7"
                              />
                            </svg>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                fill="#FE5E5E"
                              />
                            </svg>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        colspan="12"
                        className="py-6 text-left border-b border-lightBlue"
                      >
                        <span className="block text-lightGrey text-lg text-center opacity-70">
                          No Records Found
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </TabPane>

            <TabPane tab="Custom Sale" key="10">
              <div className="flex flex-col justify-between">
                <div className="flex items-center flex-wrap gap-2 mb-4">
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Parked
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Completed Sales
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Refunded
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Layby
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Backorder
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      On Account
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Exchanged
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                  <div className="flex items-center gap-2 rounded-md xxl:rounded-xl bg-ufoGreen px-2 xxl:px-4 py-1 xxl:py-2 cursor-pointer">
                    <span className="text-[0.6rem] xxl:text-[0.8rem] uppercase text-white font-semibold">
                      Partial Fullfilled
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                    </svg>
                  </div>
                </div>

                <div className="flex items-center gap-6 mb-4">
                  <form action="" className="relative w-2/6">
                    <input
                      type="text"
                      placeholder="Find by order number and/or customer name"
                      className="block leading-none py-2 xxl:py-3 pr-6 xxl:pr-10 pl-12 xxl:pl-12 border w-full rounded-full"
                    />
                    <div className="absolute top-2 xxl:top-2 left-4 xxl:left-4 w-6 h-6">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7555 18.6065L16.3182 15.2458L16.2376 15.1233C16.0878 14.9742 15.883 14.8902 15.6692 14.8902C15.4554 14.8902 15.2505 14.9742 15.1007 15.1233C12.1795 17.8033 7.67815 17.949 4.58201 15.4637C1.48586 12.9784 0.755668 8.63337 2.87568 5.31017C4.9957 1.98697 9.30807 0.716847 12.9528 2.34214C16.5976 3.96743 18.4438 7.98379 17.267 11.7276C17.1823 11.9981 17.2515 12.2922 17.4487 12.4992C17.6459 12.7062 17.9411 12.7946 18.223 12.7311C18.505 12.6676 18.7309 12.4619 18.8156 12.1914C20.2224 7.74864 18.0977 2.96755 13.8161 0.941058C9.53449 -1.08544 4.38084 0.250824 1.68905 4.08542C-1.00273 7.92001 -0.424821 13.1021 3.04893 16.2795C6.52268 19.4569 11.8498 19.6759 15.5841 16.7949L18.6277 19.7705C18.942 20.0765 19.4502 20.0765 19.7645 19.7705C20.0785 19.4602 20.0785 18.9606 19.7645 18.6503L19.7555 18.6065Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </form>
                </div>

                <div className="w-full scrollbar overflow-y-scroll">
                  <table className="table-auto w-full whitespace-nowrap">
                    <thead>
                      <tr>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">Order #</span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">Date</span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">
                              Customer
                            </span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">Title</span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">Notes</span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">Qty</span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">
                              Cost price
                            </span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">
                              Discount
                            </span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">Tax</span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">
                              Order total
                            </span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                        <th className="py-4 border-y-[0.1rem] text-left">
                          <div className="flex items-center justify-start">
                            <span className="text-sm xxl:text-lg">User</span>
                            <div className="ml-10 flex flex-col spinner">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 0.244808C6.54517 0.253822 6.54817 0.265841 6.55568 0.274856L11.8008 6.06985C12.0938 6.39588 12.0938 6.92475 11.8008 7.25078C11.7978 7.25378 11.7948 7.25529 11.7918 7.25679C11.7266 7.33573 11.6449 7.39949 11.5525 7.44361C11.4602 7.48773 11.3593 7.51114 11.2569 7.51221H0.765203C0.660951 7.51052 0.558339 7.48597 0.464618 7.44027C0.370897 7.39458 0.288353 7.32887 0.222815 7.24777L0.21981 7.25078C0.0780233 7.08673 0 6.87714 0 6.66031C0 6.44348 0.0780233 6.23389 0.21981 6.06985L5.47692 0.244808C5.54237 0.168091 5.62368 0.106485 5.71524 0.0642419C5.80681 0.0219979 5.90645 0.000121117 6.00729 0.000121117C6.10813 0.000121117 6.20777 0.0219979 6.29933 0.0642419C6.3909 0.106485 6.47221 0.168091 6.53765 0.244808Z"
                                  fill="#D7D7D7"
                                />
                              </svg>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                                  fill="#FE5E5E"
                                />
                              </svg>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          colspan="12"
                          className="py-6 text-left border-b border-lightBlue"
                        >
                          <span className="block text-lightGrey text-lg text-center opacity-70">
                            No Records Found
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </main>
  );
}

export default SalesReporting;
